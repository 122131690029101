/**
 * Site header
 */
.wrapper .container {
    max-width: 85rem;
}

.site-header {
    text-align: center;
    width: auto;
    max-width: 500px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.navbar {
    display: block;
}

.select {
    background: #ffffff !important;
}

.nav-item {
    display: inline-block;
    padding: 1rem;
}

.post-nav {
    text-align: center;
    padding: 1rem;
}

.post-title {
    display: block;
}

/**
 * override column size for mobile
 */
@media (max-width: 40rem) {
    .row .column.column-33,
    .row .column.column-34 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/**
 * Site Body
 */
body {
    background: #000000;
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    margin: 2rem 0;
    padding: 2rem 0;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

/**
 * Misc
 */
.preview-panel {
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid $color-initial;

    &:hover {
        border: 1px solid $grey-color-light;
    }
}

/* Socials */
.socials {
    list-style: none;
}

/* Page Date */
.post-date {
    font-size: 0.8em;
}
